// スクロール

const links = document.querySelectorAll('a[href^="#"]');
links.forEach((element) => {
  element.addEventListener('click', function () {
    const header = document.querySelector('.l-header') as HTMLElement;
    const buttonHamburger = document.querySelector('#js-buttonHamburger') as HTMLElement;
    const buttonHamburgerMenu = document.querySelector('#js-buttonHamburgerMenu') as HTMLElement;

    header.classList.toggle('is-open');
    buttonHamburgerMenu.classList.toggle('is-open');
    buttonHamburger.setAttribute('aria-expanded', 'false');
    buttonHamburgerMenu.setAttribute('aria-expanded', 'false');
    document.body.classList.remove('is-drawerActive');
    document.body.style.top = String(0);

    let adjust = header.offsetHeight + 50;

    if (header.classList.contains('is-fixed')) {
      adjust = header.offsetHeight + 50;
    } else {
      adjust = 200;
    }
    if (window.matchMedia('(max-width: 1200px)').matches) {
      adjust = 120;
    }
    if (window.matchMedia('(max-width: 744px)').matches) {
      adjust = 80;
    }

    let href = element.getAttribute('href');
    href = href == '#' || href == null ? 'html' : href;
    const target = document.querySelector(href);

    const rect = target?.getBoundingClientRect();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    let position = 0;
    if (rect?.top) {
      position = rect?.top + scrollTop - adjust;
    }

    const spFixed = document.querySelector('.l-header__spFixed') as HTMLElement;
    spFixed.classList.add('is-closing');

    window.setTimeout(function () {
      spFixed.classList.remove('is-closing');
    }, 300);
    element.setAttribute('aria-expanded', 'false');

    document.body.classList.remove('is-drawerActive');
    document.body.style.top = String(0);

    window.scrollTo({
      top: position,
      behavior: 'smooth',
    });
  });
});
